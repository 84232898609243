<template>
  <div class="AuctionsView">
    <DomainList :items-per-page="50"/>
  </div>
</template>

<script>
import DomainList from '@/components/DomainList';

export default {
  name: 'AuctionsView',
  components: {
    DomainList
  }
};
</script>
